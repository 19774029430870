$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";$mobilePopHeight: rem(22);
$desktopPopHeight: rem(35);

.sw-hero__cnt {
  display: grid;
  position: relative;
  z-index: map-get($z, base);
}

.sw-hero .image {
  .cmp-image__image {
    object-fit: cover;
    object-position: top;
    position: relative;
    width: 100%;
  }
}

.sw-hero__eyebrow {
  text-transform: uppercase;

  .text {
    opacity: 0.75;
  }
}

.sw-hero .parsys {
  width: 100%;

  @include media('<desktop') {
    padding-top: (map-get($grid, row-gap) / 2);
  }

  > * {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 (map-get($grid, column-gap) / -2)
      (map-get($grid, column-gap) / -2) 0;

    > * {
      padding: map-get($grid, row-gap) / 2 map-get($grid, column-gap) / 2;
    }
  }
}

.sw-hero__jumpnav {
  align-items: center;
  color: map-get($colors, body);
  display: flex;
  flex-wrap: wrap;
  padding-top: (map-get($grid, row-gap) * 1.5);

  @include media('>=desktop') {
    margin-bottom: (map-get($grid, row-gap) / -2);
  }

  @include media('<desktop') {
    padding-bottom: (map-get($grid, row-gap) / 2);
  }

  .cmp-linklist__links-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .cmp-linklist__item {
    margin: (map-get($grid, row-gap) / 2) (map-get($grid, column-gap) * 2) 0 0;
  }

  .cmp-linklist__item--link {
    align-items: center;
    color: currentColor;
    display: flex;
    font-weight: map-get($font-weights, semibold);
    white-space: nowrap;

    &:not(:hover) {
      text-decoration: none;
    }

    &:hover .sw-icon {
      transform: translateX(rem(4));
    }
  }

  .sw-icon {
    margin-left: 0.5em;
    transition: transform map-get($transitions, default);
  }
}

.sw-hero__jumpnavLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: rem(250);
}

.sw-hero__cnt--default {
  grid-template-columns: 1fr;
  grid-template-rows: rem(16) auto auto $mobilePopHeight auto;

  @include media('>=desktop') {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: rem(68) auto $desktopPopHeight auto;
  }

  &::before {
    @include fullbleed();

    background: var(--sw-hero-background);
    content: '';
    grid-column: 1 / -1;
    grid-row: 1 / 4;

    @include media('>=desktop') {
      grid-row: 1 / 3;
    }
  }

  .sw-hero__content {
    display: flex;
    flex-direction: column;
    grid-column: 1;
    grid-row: 3 / 5;
    padding: map-get($grid, row-gap) map-get($grid, column-gap);

    @include media('<desktop') {
      background: var(--sw-hero-foreground);
    }

    @include media('>=desktop') {
      grid-row: 2;
      padding: map-get($grid, row-gap) * 2 map-get($grid, column-gap) * 2;
    }

    > .title {
      margin: (map-get($grid, row-gap) / 2) 0;
    }

    > .text {
      @include media('>=desktop') {
        padding-bottom: (map-get($grid, row-gap));
      }
    }
  }

  .sw-hero__eyebrow {
    display: flex;

    .sw-icon {
      height: rem(45);
      margin-right: map-get($grid, column-gap);
      width: rem(45);
    }
  }

  .image {
    grid-column: 1;
    grid-row: 2;

    @include media('>=desktop') {
      grid-column: 2;
      grid-row: 2 / span 2;
    }
  }

  .breadcrumb {
    grid-column: 1;
    grid-row: -1;

    @include media('>=desktop') {
      grid-row: 3 / 4;
    }
  }

  @include media('<desktop') {
    &.sw-hero__cnt--drop {
      .sw-hero__content {
        grid-row: 2 / 3;
      }

      .image {
        grid-row: 3 / 5;

        .cmp-image__image {
          max-height: rem(186px);
        }
      }
    }
  }

  @include media('>=desktop') {
    &.sw-hero__cnt--drop {
      .sw-hero__content {
        background: var(--sw-hero-foreground);
        grid-row: 2 / 4;
        justify-content: flex-end;
      }

      .breadcrumb {
        grid-column: 1 / -1;
        grid-row: -1;
      }
    }
  }
}

.sw-hero__cnt--blog {
  grid-template-columns: 1fr;
  grid-template-rows: rem(16) auto auto $mobilePopHeight auto;

  @include media('>=desktop') {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: rem(68) auto $desktopPopHeight auto;
  }

  &::before {
    @include fullbleed();

    background: var(--sw-hero-background);
    content: '';
    grid-column: 1 / -1;
    grid-row: 1 / 4;

    @include media('>=desktop') {
      grid-row: 1 / 3;
    }
  }

  .sw-hero__content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    grid-column: 1;
    grid-row: 3 / 5;
    padding: map-get($grid, row-gap) map-get($grid, column-gap);

    @include media('<desktop') {
      background: var(--sw-hero-foreground);
    }

    @include media('>=desktop') {
      grid-row: 2;
      padding: map-get($grid, row-gap) * 2 map-get($grid, column-gap) * 2;
    }

    .title {
      margin: (map-get($grid, row-gap) / 2) 0;
      padding: map-get($grid, row-gap) 0;

      .cmp-title__text {
        @include heading3;
        margin-bottom: 0 !important;
      }
    }

    .text {
      @include media('>=desktop') {
        padding-bottom: (map-get($grid, row-gap));
      }
    }
  }

  .sw-hero__eyebrow {
    display: flex;
  }

  .image {
    grid-column: 1;
    grid-row: 2;

    @include media('>=desktop') {
      grid-column: 2;
      grid-row: 2 / span 2;
    }
  }

  .breadcrumb {
    grid-column: 1;
    grid-row: -1;

    @include media('>=desktop') {
      grid-row: 3 / 4;
    }
  }

  @include media('<desktop') {
    &.sw-hero__cnt--drop {

      .image {
        .cmp-image__image {
          max-height: rem(186px);
        }
      }
    }
  }

  @include media('>=desktop') {
    &.sw-hero__cnt--drop {
      .sw-hero__content {
        background: var(--sw-hero-foreground);
        grid-row: 2 / 4;
      }

      .breadcrumb {
        grid-column: 1 / -1;
        grid-row: -1;
      }
    }
  }
}

.sw-hero__cnt--pr {
  .sw-hero__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: rem(200);
    padding: map-get($grid, row-gap) map-get($grid, column-gap);
    position: relative;

    @include media('>=desktop') {
      padding: map-get($grid, row-gap) * 1.5 map-get($grid, column-gap) * 2;
    }

    &::before {
      @include fullbleed();

      background: var(--sw-hero-background);
      content: '';
    }

    > .title {
      padding: map-get($grid, row-gap) 0;

      .cmp-title__text {
        @include heading3;

        margin-bottom: 0 !important;
      }
    }

    > .parsys {
      @include media('>=desktop') {
        margin-top: (map-get($grid, row-gap) / 2);
      }
    }
  }
}

.sw-hero__cnt--focus {
  @include media('>=desktop') {
    margin-bottom: $desktopPopHeight;
  }

  .sw-hero__content {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    position: relative;
    width: 100%;

    @include media('>=desktop') {
      grid-template-columns: minmax(40%, rem(512)) 1fr;
      grid-template-rows: rem(262) minmax(1fr, auto);
      padding-top: map-get($grid, row-gap) * 2;
    }

    &::before {
      @include fullbleed();

      background: var(--sw-hero-background);
      content: '';
    }
  }

  .sw-hero__contentFocus {
    background: var(--sw-hero-background);
    display: flex;
    flex-direction: column;
    grid-column: 1;
    grid-row: 2;
    padding: map-get($grid, row-gap) map-get($grid, column-gap);
    position: relative;

    @include media('>=desktop') {
      justify-content: center;
      padding: map-get($grid, row-gap) * 2 map-get($grid, column-gap) * 6
        map-get($grid, row-gap) * 2 0;

      &::before {
        background: inherit;
        bottom: 0;
        content: '';
        height: $desktopPopHeight;
        position: absolute;
        right: 0;
        transform: translateY(100%);
        width: 100vw;
      }
    }
  }

  .image {
    grid-column: 1;
    grid-row: 1;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: map-get($z, hidden);

    @include media('>=desktop') {
      grid-column: 1 / -1;
      grid-row: 1 / -1;
    }

    .cmp-image {
      height: 100%;

      @include media('<desktop') {
        left: calc(50vw - (100vw - 100%) / 2);
        margin-left: -50vw;
        position: relative;
        width: 100vw;
      }
    }

    .cmp-image__image {
      height: inherit;
    }
  }

  .sw-hero__jumpnav {
    @include media('>=desktop') {
      margin-top: $desktopPopHeight;
    }
  }
}

.sw-hero__cnt--leadership {
  grid-template-columns: 1fr;
  grid-template-rows: rem(16) auto auto $mobilePopHeight auto;

  @include media('>=desktop') {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: rem(50) auto $desktopPopHeight auto;
  }

  &::before {
    @include fullbleed();

    background: var(--sw-hero-background);
    content: '';
    grid-column: 1 / -1;
    grid-row: 1 / 4;

    @include media('>=desktop') {
      grid-row: 1 / 3;
    }
  }

  .sw-hero__content {
    align-items: flex-start;
    background: var(--sw-hero-foreground);
    display: flex;
    flex-direction: column;
    grid-column: 1;
    grid-row: 3 / 5;
    padding: map-get($grid, row-gap) map-get($grid, column-gap);

    @include media('>=desktop') {
      grid-column: 2;
      grid-row: 2 / 4;
      padding: map-get($grid, row-gap) * 2 map-get($grid, column-gap) * 2;
    }
  }

  .text {
    flex-grow: 1;
  }

  .parsys {
    align-self: flex-end;
  }

  .image {
    background: var(--sw-hero-foreground);
    grid-column: 1;
    grid-row: 2;
    padding: 0 map-get($grid, column-gap) * 3;

    @include media('>=desktop') {
      grid-row: 2 / 4;
      padding: 0;
    }
  }

  .breadcrumb {
    grid-column: 1 / -1;
    grid-row: -1;
  }
}
